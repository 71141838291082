<template>
  <div class="page">
    <section v-if="detail">
      <div class="box">
        <span class="title">{{ detail.title }}</span>
        <img class="banner" src="@image/dy-banner.png" alt="" />
        <span class="duration"
          >{{ detail.type | typeFlag }}时长{{ detail.duration }}分钟</span
        >
        <span class="money">¥{{ detail.cash_gift }}</span>
        <span class="prompt">答谢礼金(银行卡、微信红包、支付宝收款)</span>
      </div>
      <span @click="commit" class="button">我要参与</span>
    </section>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <span>本调研属于付费调研，参与答卷请先设置您的收款账户！</span>
      <span slot="footer" class="dialog-footer">
        <div class="popUps">
          <span @click="dialogVisible = !dialogVisible" class="cancel"
            >取消</span
          >
          <span @click="xin.goUrl('/setting')" class="cancel">去认证</span>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: "",
      // list:[],
      dialogVisible: false,
    };
  },
  filters: {
    typeFlag(val) {
      if (val == 1) {
        return "问卷";
      } else if (val == 2) {
        return "电话";
      } else if (val == 3) {
        return "面访";
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    handleClose(done) {
      done();
    },
    // async getcheckList() {
    //   let res = await this.$researchApi.checkQuestions()
    //   if(res.code == 200) {
    //     this.list = res.data || []
    //   }
    // },
    // 详情
    async getDetail() {
      this.xin.isLoadding(true);
      let res = await this.$researchApi.questionnaireDetail({
        questionnaire_id: this.$route.query.id,
      });
      if (res.code == 200) {
        this.detail = res.data || [];
      }
      // await this.getcheckList()
      this.xin.isLoadding();
    },
    // 参与
    async commit() {
      // let ischeck = false
      // for(let j in this.list) {
      //   if(this.$route.query.id == this.list[j]) {
      //     ischeck = true
      //     break
      //   }
      // }
      // if(ischeck) {
      //   return this.$message({ message: '您已参加了该调研!', type: 'error' });
      // }

      let flag = false; // 是否有默认收款账号
      let res = await this.$userApi.getListBank();
      if (res.code == 200) {
        let arr = res.data || [];
        if (arr.length) {
          for (let i in arr) {
            if (arr[i].is_default) {
              flag = true;
              break;
            }
          }
        }
      }

      // 有收款账户或者没有调研礼金
      if (flag || parseFloat(this.detail.cash_gift) <= 0) {
        let url;
        if (this.detail.type == 1) {
          // 在线问卷
          url =
            "/research-questionnaire-informedConsent?id=" +
            this.$route.query.id;
        } else if (this.detail.type == 2) {
          // 电话
          url =
            "/research-questionnaireTelephone-selectionPeriod?id=" +
            this.$route.query.id;
        } else if (this.detail.type == 3) {
          //  面访
          url = "/research-interview-index?id=" + this.$route.query.id;
        }
        this.xin.goUrl(url);
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .el-dialog {
    padding: 50px 80px;
    box-sizing: border-box;
    .el-dialog__body {
      color: #333333;
      font-size: 24px;
      text-align: center;
      line-height: 40px;
    }
    .el-dialog__footer {
      text-align: center;
      margin-top: 50px;
      .popUps {
        display: flex;
        flex-direction: row;
        justify-content: center;
        > .cancel {
          cursor: pointer;
          &:first-child {
            margin-right: 30px;
          }
          &:last-child {
            background: $--themeColor;
            border: 0;
            color: #ffffff;
          }
          width: 170px;
          height: 50px;
          background: #ffffff;
          border: 1px solid $--themeColor;
          border-radius: 26px;
          line-height: 50px;
          color: $--themeColor;
        }
      }
    }
  }
}
.page {
  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 550px;
      height: 620px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(3, 219, 198, 0.2);
      margin: 50px 0 30px 0;
      font-size: 24px;
      color: $--themeColor;
      padding: 49px 107px;
      box-sizing: border-box;
      .title {
        font-weight: bold;
        line-height: 32px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
      > .banner {
        width: 311px;
        height: 255px;
        object-fit: cover;
        margin: 46px 0 30px 0;
      }
      > .duration {
        font-size: 24px;
        color: #333;
        margin-bottom: 30px;
      }
      > .money {
        font-size: 36px;
        margin-bottom: 20px;
      }
      > .prompt {
        font-size: 14px;
        color: #8f8f8f;
      }
    }
    > .button {
      cursor: pointer;
      width: 384px;
      height: 46px;
      background: $--themeColor;
      border-radius: 23px;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
      line-height: 46px;
    }
  }
}
</style>